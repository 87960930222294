<template>
  <div>
    <div>
      <v-btn
        @click="importFileFlotingButton"
        :style="colorFlotingButton"
        fab
        dark
        fixed
        bottom
        right
        :disabled="opensnackbar"
      >
        <input
          ref="importdoc"
          class="d-none"
          type="file"
          @change="uploadFileFlotingButton"
          multiple
        />
        <v-icon size="40">mdi-plus</v-icon>
      </v-btn>
    </div>
    <v-snackbar
      top
      v-model="opensnackbar"
      :timeout="timeout"
      class="fixed-dialog"
      text
      rounded="lg"
    >
      <v-list style="padding-top: 0px; border-radius: 20px;" class="mr-n2">
        <v-toolbar
          :color="color.theme"
          dense
          short
          flat
          fab
          :style="stylesnackbar"
          class="mb-n2"
        >
          <v-list-item v-if="percentuploadedallfile !== 100">
            <v-list-item-content class="ml-n2 mb-n4 text-left">
              <v-list-item-title style="color: white; font-size: 14px">{{ $t("dragdrop.uploading") }}<br /><span
                  >{{
                    length_complete + " " + $t("dragdrop.of") + " " + totalall
                  }}
                  {{ $t("dragdrop.file") }}</span
                ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-else>
            <v-list-item-content v-if="count_cancel > 0" class="text-left ml-n2">
              <v-list-item-title style="color: white; font-size: 13px">{{ $t("dragdrop.cancelupload") }}</v-list-item-title >
            </v-list-item-content>
            <v-list-item-content v-else class="text-left ml-n2">
              <v-list-item-title style="color: white; font-size: 13px">{{ $t("dragdrop.uploadsuccess") }}</v-list-item-title >
            </v-list-item-content>
          </v-list-item>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="openclose === true ? false : true"
            color="white"
            @click="opensnackbar = false, clearDataSnackbarUpload()"
            icon
            x-small
            fab
          >
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
          <template v-slot:extension>
            <v-spacer></v-spacer>
            <v-btn
              small
              @click="detailuploadclick()"
            >
              <span
                v-if="count_uploadfail > 0"
              >
                {{ $t("dragdrop.cannotupload") }}&nbsp;{{
                  count_uploadfail
                }}
                {{ $t("dragdrop.of") }} {{ totalall }}
                {{ $t("dragdrop.file") }}
              </span>
              <span v-else>
                {{ $t("dragdrop.detail") }}
              </span>
            </v-btn>
          </template>
        </v-toolbar>
      </v-list>
    </v-snackbar>
    <detailupload
      :show="opendetailupload"
      :dataupload="showfileupload"
      @hidedetailupload="hidedetail()"
      @cancel="cancel_upload()"
      @canceloneitem="cancel_upload_one_item"
      :length_processing="length_processing"
    ></detailupload>
    <dialogalertduplicatefile
      :show="openalertduplicatefile"
      :listfileduplicate="listfileduplicate"
      @skip="setStatusFileDuplicate"
      @replace="setStatusFileDuplicate"
      @copy="setStatusFileDuplicate"
    ></dialogalertduplicatefile>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import { v4 as uuidv4 } from "uuid";
import axios, { CancelToken } from "axios";
import gb_setIconFiles from "@/globalFunctions/setIconFiles";
import gbfsizeDataConverter from "@/globalFunctions/sizeDataConverter.js";
const detailupload = () => import("@/components/optional/dialog-detailupload");
const dialogalertduplicatefile = () =>
  import("@/components/optional/dialog-alert-duplicate-file.vue");
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  data() {
    return {
      moredetail: false,
      loaddataprogress: false,
      selectingImportFlotintButton: false,
      fileImportFlotintButton: null,
      opensnackbar: false,
      source: "",
      showfileupload: [],
      countuploadfiles: 0,
      datanameuploading: "",
      openbtnclose: false,
      panel_expansion: [1],
      tempshowfileupload: [],
      count_cancel: 0,
      countuploadfilesfail: 0,
      total: 0,
      opencancel: true,
      loading: false,
      storage_full: false,
      timeout: -1,
      opendetailupload: false,
      arrayfaildataupload: [],
      listfileduplicate: [],
      openalertduplicatefile: false,
      count_upload: 0,
      remain_storage: 0,
      stacksizefile: 0,
      openclose: false,
      indexupload: 0,
      indexuploadduplicate: 0,
      checkduplicatezone: false,
      selecteallduplicate: [],
    };
  },
  filters: {
    subStr: function(val) {
      if (val.length > 25) return val.substring(0, 25) + "...";
      else return val;
    },
  },
  components: {
    detailupload,
    dialogalertduplicatefile,
  },
  props: ["departmentid"],
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
      "storage_usage",
      "storage_usage_biz",
      "storage_max_personal_biz",
      "storage",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageMax",
      "dataRemainStorage",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    percentuploadedallfile() {
      return (
        this.showfileupload.reduce((a, b) => {
          let x = b["value"] === 500 ? 100 : b["value"];
          return a + x;
        }, 0) / this.showfileupload.length
      );
    },
    // สีพื้นหลังของ Floating Button (ปุ่มลอยขวาล่าง)
    colorFlotingButton() {
      if (this.$vuetify.theme.dark) {
        return "background: #9A9A9A;";
      } else {
        return "background: linear-gradient(270deg, #176C91 0%, #71B5D8 100%);";
      }
    },
    stylesnackbar() {
      return "border-radius: 10px 10px 10px 10px; width: auto;";
    },
    // จำนวนที่ไม่ใช่ start
    length_processing() {
      return (
        this.showfileupload.filter((item) => {
          return (
            item.status.code === "fail" ||
            item.status.code === "success" ||
            item.status.code === "duplicate" ||
            item.status.code === "skip" ||
            item.status.code === "cancel"
          );
        }).length
      );
    },
    // จำนวนของอัปไม่สำเร็จและยกเลิก
    count_uploadfail() {
      return (
        this.showfileupload.filter((item) => {
          return (
            item.status.code === "fail" ||
            item.status.code === "cancel"
          );
        }).length
      );
    },
    // จำนวนการเสร็จกระบวนการ
    length_complete() {
      return (
        this.showfileupload.filter((item) => {
          return (
            item.status.code === "fail" ||
            item.status.code === "success" ||
            item.status.code === "skip" ||
            item.status.code === "cancel"
          );
        }).length
      );
    },
    totalall() {
      return this.showfileupload.length;
    }
  },
  watch: {
    length_processing(val) {
      // เช็คว่ามีไฟล์หรือไม่
      if (this.showfileupload.length !== 0) {
        // เช็คว่าทุกไฟล์อัปเสร็จสิ้นหรือยัง
        if (this.length_complete === this.showfileupload.length) {
          this.openclose = true;
          this.checkduplicatezone = false;
          this.selecteallduplicate = [];
          setTimeout(() => {
            this.reloaddata();
          }, 1500);
        } else {
          // เช็คว่าอยู่ไม่ได้อยู่ในกระบวนการอัปไฟล์ซ้ำ
          if (this.checkduplicatezone === false) {
            // เช็คว่าได้ทำงานกับทุกไฟล์แล้วหรือไม่
            if (val === this.showfileupload.length) {
              // เช็คว่ามีไฟล์ซ้ำหรือไม่
              if (this.listfileduplicate.length > 0) {
                this.openalertduplicatefile = true;
                this.checkduplicatezone = true;
              }
            }
          }
        }
      }
    },
    count_upload(val) {
      // ต้องไม่มีการ cancel ทั้งหมด
      if (this.count_cancel === 0) {
        // ต้องมีไฟล์
        if (this.showfileupload.length !== 0) {
          // index การอัปโหลด + 1 ต้องน้อยกว่าจำนวนไฟล์ที่อัปโหลด
          if (this.indexupload + 1 < this.showfileupload.length) {
            // ลิมิตการอัปโหลดต้องน้อยกว่า 5
            if (val < 5) {
              let i;
              // อยู่ในกระบวนการอัปโหลดไฟล์ซ้ำหรือไม่
              if (this.checkduplicatezone === true) {
                this.indexuploadduplicate++
                i = this.selecteallduplicate[this.indexuploadduplicate].id
                this.checkUploadFiles(i);
              } else {
                this.indexupload++
                i = this.indexupload;
                console.log("this.indexuploadd", i);
                this.checkUploadFiles(i);
              }
            }
          }
        }
      }
    }
  },
  methods: {
    // ฟังก์ชันเปิดรายละเอียด
    detailuploadclick() {
      this.opendetailupload = true;
      this.opensnackbar = false;
    },
    // ฟังก์ชันสลับจาก รายละเอียด ไป snackbar
    hidedetail() {
      this.opendetailupload = false;
      this.opensnackbar = true;
    },
    // ฟังก์ชันปิด reload data
    reloaddata() {
      this.stacksizefile = 0;
      this.$store.dispatch("check_storage", this.dataAccountId);
      this.$emit("loadfile");
    },
    // เด้งให้เลือกไฟล์
    importFileFlotingButton() {
      this.selectingImportFlotintButton = true;
      this.fileImportFlotintButton = null;
      this.$refs.importdoc.value = null;
      this.listfileduplicate = [];
      // this.showfileupload = [];
      window.addEventListener(
        "focus",
        () => {
          this.selectingImportFlotintButton = false;
        },
        { once: true }
      );
      this.$refs.importdoc.click();
    },
    // ฟังก์ชันยกเลิกการอัปโหลด 1 ไฟล์
    cancel_upload_one_item(item) {
      this.checkcancelone = true;
      item.canceltoken.cancel();
      this.stacksizefile = this.stacksizefile - item.size
      this.count_upload--
      // this.count_uploadfail++
    },
    // ฟังก์ชันยกเลิกการอัปโหลดทั้งหมด
    cancelUpload() {
      this.count_cancel = 1;
      let datacancel = this.showfileupload.filter((item) => {
        return (
          item.status.code === "start" ||
          item.status.code === "duplicate"
        );
      });
      datacancel.map(obj => {
        this.showfileupload[obj.id]["canceltoken"].cancel()
        this.showfileupload[obj.id]["color"] = "red";
        this.showfileupload[obj.id]["status"] = {
          code: "cancel",
          message: "dragdrop.cancelupload",
          devMessage: ""
        };
      });
      this.openclose = true;
    },
    // ฟังก์ชันการทำงานตาม action ที่ผู้ใช้เลือกเมื่อไฟล์ซ้ำ
    async setStatusFileDuplicate(selectedduplicate, fileduplicate) {
      if (fileduplicate === "") {
        // stamp ว่าไฟล์ถูกยกเลิก
        selectedduplicate.map(obj => {
          this.showfileupload[obj.id]["color"] = "red";
          this.showfileupload[obj.id]["status"] = {
            code: "cancel",
            message: "dragdrop.cancelupload",
            devMessage: ""
          };
          let i = this.listfileduplicate.indexOf(obj.id);
          this.listfileduplicate.splice(i, 1);
        });
        // เช็คว่ายกเลิกเสร็จแล้วไฟล์เสร็จสิ้นหรือยัง
        if (this.length_complete === this.showfileupload.length) {
          this.openclose = true;
        }
      } else {
        let checkconcat;
        let indexduplicate;
        // เข้าฟังก์ชันครั้งแรก (เฉพาะหน้า desktop)
        if (this.selecteallduplicate.length === 0) {
          this.selecteallduplicate = [...selectedduplicate];
          indexduplicate = selectedduplicate[0]["id"]
        } else {
          this.selecteallduplicate.concat(selectedduplicate);
          checkconcat = true;
        }
        // stamp เป็นค่าเริ่มต้น
        selectedduplicate.map(obj => {
          this.showfileupload[obj.id]["color"] = this.color.theme;
          this.showfileupload[obj.id]["file_duplicate"] = fileduplicate;
          this.showfileupload[obj.id]["status"] = {
            code: "start",
            message: "dragdrop.uploading",
            devMessage: ""
          };
          let i = this.listfileduplicate.indexOf(obj.id);
          // ลบข้อมูลออกจากรายการไฟล์ซ้ำ
          this.listfileduplicate.splice(i, 1);
        });
        if (this.count_cancel !== 1) {
          if (checkconcat !== true) {
            this.count_upload = 0;
            this.checkUploadFiles(indexduplicate);
          }
        }
      }
      // สั่งปิด dialog ไฟล์ซ้ำ
      if (this.listfileduplicate.length === 0) {
        this.openalertduplicatefile = false;
      }
    },
    // ฟังก์ชันเช็คไฟล์ซ้ำ
    async checkDuplicateData(i) {
      // หาที่ folder_id ที่เป็นที่อยู่ของไฟล์/โฟลเดอร์
      this.parentfolder = this.sendParentfolder();
      let payload = {
        folder_id: this.parentfolder,
        data_type: "file",
        data_name: this.showfileupload[i]["name"],
      };
      let auth = await gbfGenerate.generateToken();
      try {
        let response = await this.axios({
          method: "POST",
          url:
            process.env.VUE_APP_SERVICE_UPLOAD_API +
            "/api/v1/check_duplicate_data",
          // cancelToken: this.source.token,
          cancelToken: this.showfileupload[i]["canceltoken"].token,
          data: payload,
          headers: { Authorization: auth.code },
        });
        if (response.data.status === "OK") {
          if (
            response.data.message === "duplicate foldername" ||
            response.data.message === "duplicate filename"
          ) {
             this.listfileduplicate.push(this.showfileupload[i]);
            this.showfileupload[i]["folderid"] = this.parentfolder;
            this.showfileupload[i]["value"] = 100;
            this.showfileupload[i]["status"] = {
              code: "duplicate",
              message: "dragdrop.duplicatefile",
              devMessage: ""
            };
            this.showfileupload[i]["color"] = "warning";
            return new Promise(async (resolve) => {
              resolve({
                status: "duplicate",
                msg: response.data.message,
              });
            });
          } else {
            return new Promise(async (resolve) => {
              resolve({
                status: "unique",
                msg: response.data.message,
              });
            });
          }
        } else {
          // this.count_uploadfail++;
          this.showfileupload[i]["color"] = "red";
          this.showfileupload[i]["value"] = 100;
          this.showfileupload[i]["status"] = {
            code: "fail",
            message: "dragdrop.duplicatefail",
            devMessage: response.data.errorMessage
          };
          return new Promise(async (resolve) => {
            resolve({
              status: "upload denied",
              msg: "dragdrop.duplicatefail",
            });
          });
        }
      } catch (err) {
        console.log("err", err);
        if (this.count_cancel === 1 || this.checkcancelone === true) {
          // this.count_uploadfail++;
          this.showfileupload[i]["color"] = "red";
          this.showfileupload[i]["value"] = 100;
          this.showfileupload[i]["status"] = {
            code: "cancel",
            message: "dragdrop.cancelupload",
            devMessage: ""
          };
          return new Promise(async (resolve) => {
            resolve({
              status: "upload denied",
              msg: "dragdrop.cancelupload",
            });
          });
        } else {
          // this.count_uploadfail++;
          this.showfileupload[i]["color"] = "red";
          this.showfileupload[i]["value"] = 100;
          this.showfileupload[i]["status"] = {
            code: "fail",
            message: "dragdrop.duplicatefail",
            devMessage: err
          };
          return new Promise(async (resolve) => {
            resolve({
              status: "upload denied",
              msg: "dragdrop.duplicatefail",
            });
          });
        }
      }
    },
    // ฟังก์ชันเตรียมอัปโหลดไฟล์ครั้งแรกสำหรับ Floting Button
    async uploadFileFlotingButton(e) {
      try {
        this.opensnackbar = true;
        // เช็คว่ามีการอัปโหลดซ้ำจากการเลือก file_duplicate
        this.fileImportFlotintButton = e.target.files;
        this.total = e.target.files.length;
        this.createDataUpload();
        this.checkUploadFiles(this.indexupload);
      } catch(e) {
        console.log("e", e);
      }
    },
    // ฟังก์ชันอัปโหลดไฟล์สำหรับ Floting Button
    async checkUploadFiles(i) {
      let responsecheckduplicate;
      if (this.count_cancel === 0) {
        if (this.count_upload <= 5) {
          this.count_upload++;
          this.source = CancelToken.source();
          console.log("this.source",  this.source.token);
          // เก็บ canceltoken ของแต่ละ request
          this.showfileupload[i].canceltoken = this.source;
          await this.$store.dispatch("check_storage", this.dataAccountId);
          this.remain_storage = await gbfsizeDataConverter.convertStorageToByte(
            this.dataRemainStorage
          );
          // เช็ค storage
          if (
            this.remain_storage < this.showfileupload[i]["size"]
          ) { 
            this.showfileupload[i]["value"] = 100;
            this.showfileupload[i]["status"] = {
              code: "fail",
              message: "dragdrop.yourspacenotenough",
              devMessage : "",
            };
            this.showfileupload[i]["color"] = "red";
            this.count_upload--;
          } 
          // เช็ค storage + ขนาดไฟล์ที่กำลังอัปโหลด
          else if (
            this.remain_storage < 0 ||
            (this.remain_storage <
            this.stacksizefile + this.showfileupload[i]["size"] &&
            this.showfileupload[i]["type"] !== "folder")
          ) {
            this.openclose = true;
            this.showfileupload[i]["value"] = 100;
            this.showfileupload[i]["status"] = {
              code: "fail",
              message: "dragdrop.yourspacenotenough",
              devMessage : "",
            };
            this.showfileupload[i]["color"] = "red";
            this.count_upload--;
          } else {
            // เช็คไฟล์ซ้ำ
            responsecheckduplicate =
              this.showfileupload[i]["file_duplicate"] !== ""
                ? ""
                : await this.checkDuplicateData(i);
            console.log("loop 1 2 3");
            if (responsecheckduplicate.status === "duplicate") {
              this.count_upload--;
            } else if (responsecheckduplicate.status === "upload denied") {
              this.count_upload--;
            } else {
              // เก็บขนาดไฟล์ที่อัปโหลด
              this.stacksizefile = this.stacksizefile + this.showfileupload[i]["size"]
              if (this.showfileupload[i]["size"] > 104857600) {
                this.loopUploadChunk(this.showfileupload[i]);
              } else {
                this.loopUploadNoChunk(this.showfileupload[i]);
              }
            }
          }
        } else {
          console.log("ghueguheouegohgoueogheoughoughouhgouhgoehgohu");
        }
      }
    },
    // สร้าง data สำหรับเก็บข้อมูลไฟล์แต่ละไฟล์
    createDataUpload() {
      for (let i = 0; i < this.fileImportFlotintButton.length; i++) {
        // ปั้นดาต้าใหม่
        let datafileupload = {};
        datafileupload["file"] = this.fileImportFlotintButton[i];
        datafileupload["name"] = this.fileImportFlotintButton[i].name;
        datafileupload["size"] = this.fileImportFlotintButton[i].size;
        datafileupload["numchunk"] = 0;
        datafileupload["allchunk"] = [];
        datafileupload["value"] = 0;
        datafileupload["canceltoken"] = "";
        datafileupload["id"] = i;
        datafileupload["chunkkey"] = "";
        datafileupload["file_duplicate"] = "";
        datafileupload["status"] = {
          code: "start",
          message: "dragdrop.uploading",
          devMessage: ""
        }
        datafileupload["msg"] = "";
        datafileupload["icon"] = gb_setIconFiles.fn_seticon(
          this.fileImportFlotintButton[i].name.split(/\.(?=[^\.]+$)/)[1]
        );
        this.showfileupload.push(datafileupload);
      }
    },
    // วนลูป อัปโหลดไฟล์ chunk file
    async loopUploadChunk(item) {
      try {
        // เรียกฟังก์ชันสำหรับการสร้าง blob ในการอัปโหลดแบบ chunk
        await this.createChunk(item);
        // เรียกฟังก์ชันสำหรับการสร้าง key ของ chunk
        await this.uuidGenerate(item);
        for (let i = 0; i < item.allchunk.length; i++) {
          if (this.remain_storage > item["size"]) {
            item.numchunk = i;
            let payloadfilechunk = await this.checkPayloadFileChunk(item);
            let filechunk = await this.uploadFileChunk(payloadfilechunk, item, i);
            if (filechunk.status !== "upload success") {
              break;
            }
            if (i === item.allchunk.length - 1) {
              let checkpayloadfilemerge = await this.checkpayloadfilemerge(item);
              await this.uploadfilemerge(checkpayloadfilemerge, item);
              await this.$store.dispatch("check_storage", this.dataAccountId);
              this.remain_storage = await gbfsizeDataConverter.convertStorageToByte(
                this.dataRemainStorage
              );
              this.count_upload--;
            }
          } else {
            item["value"] = 100;
            item["status"] = {
              code: "fail",
              message: "dragdrop.yourspacenotenough",
              devMessage: "",
            };
            item["color"] = "red";
            this.stacksizefile =
              this.stacksizefile - item["size"];
            break;
          }
        }
      } catch (err) {
        console.log("err", err);
        this.count_upload--;
        if (this.count_cancel === 1 || this.checkcancelone === true) {
          // this.count_uploadfail++;
          item["value"] = 100;
          item["status"] = {
            code: "cancel",
            message: "dragdrop.cancelupload",
            devMessage: "",
          };
          item["color"] = "red";
          this.percentfileuploading = item["value"];
        } else {
          // this.count_uploadfail++;
          item["value"] = 100;
          item["status"] = {
            code: "fail",
            message: "dragdrop.cannotdetailupload",
            devMessage: err,
          };
          item["color"] = "red";
          this.stacksizefile =
            this.stacksizefile - item["size"];
        }
      }
    },
    // สร้างคีย์สำหรับ chunk
    uuidGenerate(item) {
      let myuuid = uuidv4();
      this.showfileupload[item.id].chunkkey = myuuid;
    },
    // แบ่งไฟล์เป็น chunk
    async createChunk(item) {
      let allchunk = [];
      var size = 5242880;
      var chunklength = 0;
      try {
        chunklength = Math.ceil(
          this.fileImportFlotintButton[item.id].size / size
        );
        for (let i = 0; i < chunklength; i++) {
          allchunk.push(
            this.fileImportFlotintButton[item.id].slice(
              i * size,
              Math.min(
                i * size + size,
                this.fileImportFlotintButton[item.id].size
              ),
              this.fileImportFlotintButton.type
            )
          );
        }
        this.showfileupload[item.id]["allchunk"] = allchunk;
      } catch (ex) {
        console.log(ex);
      }
    },
    // ฟังก์ชันสร้าง payload ของ chunk แต่ละอัน
    async checkPayloadFileChunk(item) {
      let parent = this.sendParentfolder();
      try {
        const formData = new FormData();
        formData.append(`account_id`, this.dataAccountId);
        formData.append(`folder_id`, parent);
        formData.append(`size_file`, item.size);
        formData.append(`file_part`, item.name + `.part_${item.numchunk}`);
        formData.append(`key`, this.showfileupload[item.id]["chunkkey"]);
        formData.append(`file_duplicate`, item["file_duplicate"]);
        if (item.allchunk[item.numchunk]) {
          formData.append(`blob`, item.allchunk[item.numchunk]);
        }
        return formData;
      } catch (ex) {
        console.log(ex);
      }
    },
    sendParentfolder() {
      if (this.$route.params.id === undefined) {
        this.parentfolder = this.account_active["directory_id"];
      } else {
        this.parentfolder = this.$route.params.id;
      }
      return this.parentfolder;
    },
    // ฟังก์ชันอัปโหลดไฟล์ที่เป็น chunk
    async uploadFileChunk(payload, item, i) {
      let len_file = item.allchunk.length;
      try {
        let result = await this.axios({
          method: "POST",
          url:
            process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/upload_files_chunk",
          cancelToken: item["canceltoken"].token,
          data: payload,
          headers: {},
        });
        if (result.data.status === "OK") {
          item["value"] = Math.round(
            (i / len_file) * 100
          );
          item["folderid"] = this.parentfolder;
          return new Promise((resolve, reject) => {
            resolve({ status: "upload success", msg: "" });
          });
        } else {
          item["status"] =  
            result.data.errorMessage === "Your space not enough"
              ? {
                  code: "fail",
                  message: "dragdrop.yourspacenotenough",
                  devMessage: "",
                }
              : {
                  code: "fail",
                  message: "dragdrop.cannotdetailupload",
                  devMessage: result.data.errorMessage,
                };
          item["color"] = "red";
          item["value"] = 100;
          // this.count_uploadfail++;
          this.stacksizefile =
            this.stacksizefile - item["size"];
          return new Promise((resolve, reject) => {
            resolve({ status: "upload denied", msg: result.data.errorMessage });
          });
        }
      } catch (err) {
        console.log("err", err);
        if (this.count_cancel === 1 || this.checkcancelone === true) {
          item["color"] = "red";
          item["value"] = 100;
          item["status"] = {
            code: "cancel",
            message: "dragdrop.cancelupload",
            devMessage: "",
          };
          return new Promise(async (resolve) => {
            resolve({
              status: "upload denied",
              msg: this.$t("dragdrop.cancelupload"),
            });
          });
        } else {
          this.stacksizefile =
            this.stacksizefile - item["size"];
          item["color"] = "red";
          item["value"] = 100;
          item["status"] = {
            code: "fail",
            message: "dragdrop.cannotdetailupload",
            devMessage: err,
          };
          return new Promise(async (resolve) => {
            resolve({
              status: "upload denied",
              msg: err,
            });
          });
        }
      }
    },
    // ฟังก์ชันอัปโหลดไฟล์ no chunk
    async loopUploadNoChunk(item) {
      let payloadnochunk = await this.checkPayLoadNoChunk(item);
      let auth = await gbfGenerate.generateToken();
      try {
        let result = await this.axios({
          method: "POST",
          url: process.env.VUE_APP_SERVICE_UPLOAD_FILE + "/api/upload_files_v2",
          cancelToken: this.showfileupload[item.id].canceltoken.token,
          data: payloadnochunk,
          headers: { Authorization: auth.code },

          onUploadProgress: (e) => {
            if ((e.loaded / e.total) * 100 >= 95) {
              this.showfileupload[item.id]["value"] = 95;
            } else {
              this.showfileupload[item.id]["value"] = parseInt(
                (e.loaded / e.total) * 100
              );
            }
          },
        });
        if (result.data.status === "OK") {
          console.log("test2");
          this.count_upload--;
          this.stacksizefile =
            this.stacksizefile - item["size"];
          item["value"] = 100;
          item["status"] = {
            code: "success",
            message: "dragdrop.uploadsuccess",
            devMessage: "",
          };
          item["color"] = "green";
        } else {
          console.log("test else");
          this.count_upload--;
          this.stacksizefile =
            this.stacksizefile - item["size"];
          item["value"] = 100;
          item["status"] =
            result.data.errorMessage === "Your space not enough"
              ? {
                  code: "fail",
                  message: "dragdrop.yourspacenotenough",
                  devMessage: "",
                }
              : {
                  code: "fail",
                  message: "dragdrop.cannotdetailupload",
                  devMessage: result.data.errorMessage,
                };
          item["color"] = "red";
        }
      } catch (err) {
        console.log("err", err);
        this.count_upload--;
        if (this.count_cancel === 1 || this.checkcancelone === true) {
          item["value"] = 100;
          item["status"] = {
            code: "cancel",
            message: "dragdrop.cancelupload",
            devMessage: ex,
          };
          item["color"] = "red";
        } else {
          this.stacksizefile =
            this.stacksizefile - item["size"];
          item["value"] = 100;
          item["status"] = {
            code: "fail",
            message: "dragdrop.cannotdetailupload",
            devMessage: ex,
          };
          item["color"] = "red";
        }
      }
    },
    // ฟังก์ชันสร้าง payload สำหรับ merge chunk
    async checkpayloadfilemerge(item) {
      let parent;
      const formData = new FormData();
      try {
        parent = this.sendParentfolder();
        formData.append(`account_id`, this.dataAccountId);
        formData.append(`user_id`, this.dataUsername);
        formData.append(`folder_id`, parent);
        formData.append(`file_duplicate`, item["file_duplicate"]);
        formData.append(`file_name`, item.name);
        formData.append(`remark`, "");
        formData.append(`len_file`, Math.ceil(item.allchunk.length));
        formData.append(`key`, this.showfileupload[item.id]["chunkkey"]);
        return formData;
      } catch (ex) {
        console.log(ex);
      }
    },
    // ฟังก์ชันสร้าง payload สำหรับไฟล์ no chunk
    async checkPayLoadNoChunk(item) {
      let parent;
      const formData = new FormData();
      try {
        parent = this.sendParentfolder();
        formData.append("file", item.file);
        formData.append("file_name", item.name);
        formData.append("size_file", item.size);
        formData.append("folder_id", parent);
        formData.append("user_id", this.dataUsername);
        formData.append("department_id", this.departmentid);
        formData.append("account_id", this.dataAccountId);
        formData.append("file_duplicate", item["file_duplicate"]);
        formData.append("remark", "");
        return formData;
      } catch (ex) {
        console.log(ex);
      }
    },
    // ฟังก์ชัน merge chunk
    async uploadfilemerge(payloadmerge, item) {
      this.count_merge++;
      let auth = await gbfGenerate.generateToken();
      try {
        let result = await this.axios({
          method: "POST",
          url:
            process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/merge_files_chunk",
          cancelToken: this.showfileupload[item.id]["canceltoken"].token,
          data: payloadmerge,
          headers: { Authorization: auth.code },

          onUploadProgress: (e) => {
            if ((e.loaded / e.total) * 100 >= 95) {
              this.showfileupload[item.id]["value"] = 99;
            } else {
              this.showfileupload[item.id]["value"] = parseInt(
                (e.loaded / e.total) * 100
              );
            }
          },
        });
        item.numchunk = 0;
        if (result.data.status === "OK") {
          this.count_merge--;
          this.stacksizefile =
            this.stacksizefile - item.size;
          item["value"] = 100;
          item["status"] = {
            code: "success",
            message: "dragdrop.uploadsuccess",
            devMessage: "",
          };
          item["color"] = "green";
        } else {
          this.count_merge--;
          this.stacksizefile =
            this.stacksizefile - item["size"];
          item["status"] =
            result.data.errorMessage === "Your space not enough"
              ? {
                  code: "fail",
                  message: "dragdrop.yourspacenotenough",
                  devMessage: "",
                }
              : {
                  code: "fail",
                  message: "dragdrop.cannotdetailupload",
                  devMessage: result.data.errorMessage,
                }
              ;
          item["color"] = "red";
          item["value"] = 100;
        }
      } catch (err) {
        if (this.count_cancel === 1 || this.checkcancelone === true) {
          this.count_merge--;
          item["status"] = {
            code: "cancel",
            message: "dragdrop.cancelupload",
            devMessage: result.data.errorMessage,
          };
          item["color"] = "red";
          item["value"] = 100;
        } else {
          this.count_merge--;
          this.stacksizefile =
            this.stacksizefile - item["size"];
          item["status"] = {
            code: "cancel",
            message: "dragdrop.cannotdetailupload",
            devMessage: err,
          };
          item["color"] = "red";
          item["value"] = 100;
        }
      }
    },
    // ฟังก์ชัน clear ข้อมูล
    clearDataSnackbarUpload() {
      setTimeout(() => {
        this.count_cancel = 0;
        this.countuploadfiles = 0;
        this.countuploadfilesfail = 0;
        this.openbtnclose = false;
        this.count_upload = 0;
        this.remain_storage = 0;
        this.stacksizefile = 0;
        this.openclose = true;
        this.indexupload = 0;
        this.indexuploadduplicate = 0,
        this.checkduplicatezone = false,
        this.selecteallduplicate = [];
        this.showfileupload = [];
        this.tempshowfileupload = [];
      }, 1000);
    },
  },
};
</script>
<style>
.fixed-dialog {
  bottom: 40px;
  position: fixed;
  z-index: 0;
}
.v-snack__content {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
</style>
